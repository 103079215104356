.img-holder {
  display: flex;
}
.img {
  width: 32px;
  height: 24px;
  object-fit: cover;
}
.Toastify__toast {
  justify-content: center !important;
}
.Toastify__toast--error {
  background-image: linear-gradient(
    to top,
    #f7412e 0%,
    #e13b3b 100%
  ) !important;
}

#content {
  padding: 4px;
}
