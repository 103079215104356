.img-holder {
  display: flex;
}
.img {
  width: 32px;
  height: 24px;
  object-fit: cover;
}
.Toastify__toast {
  justify-content: center !important;
}
.Toastify__toast--error {
  background-image: linear-gradient(
    to top,
    #f7412e 0%,
    #e13b3b 100%
  ) !important;
}

#content {
  padding: 4px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




